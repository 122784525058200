import Image, { ImageProps } from "components/base/Image/Image";
import { ContentAreaProps } from "components/utils/ContentArea/ContentArea";
import { IconMS, RichTextBlock } from "SiteComponents";
import { LinkProps } from "src/base-props/LinkProps";
import styles from "./ContactBlockItem.module.scss";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "src/utils/useWindowSize";
import Truncate from "components/base/Truncate/Truncate";

export interface ContactBlockItemProps {
  className?: string;
  name: string;
  subHeading?: string;
  image?: ImageProps;
  ingress?: string;
  email?: string;
  phoneNumber?: string;
  link?: LinkProps;
  small?: boolean;
  horizontal?: boolean;
  text?: ContentAreaProps;
  showMoreText?: string;
  showLessText?: string;
  truncateText: boolean;
}

const ContactBlockItem = ({
  className,
  name,
  subHeading,
  image,
  ingress,
  email,
  phoneNumber,
  link,
  text,
  small,
  horizontal,
  showLessText,
  showMoreText,
  truncateText,
}: ContactBlockItemProps) => {
  return (
    <div
      className={classNames(
        className,
        { [styles.small]: small, [styles.horizontal]: horizontal },
        styles.contactBlockItem
      )}
    >
      {image && (
        <div className={styles.image}>
          <Image src={image.src} alt={image.alt} hotspot={image.hotspot} />
        </div>
      )}
      <div
        className={classNames(styles.contentContainer, {
          [styles.smallHorizontal]: small && horizontal,
          [styles.horizontal]: horizontal && !small,
          [styles.noSubHeading]: !subHeading,
        })}
      >
        {subHeading && <p className={styles.subHeading}>{subHeading}</p>}
        <h3>{name}</h3>
        {ingress && <p className={styles.ingress}>{ingress}</p>}
        {(email || phoneNumber || link?.url) && (
          <ul>
            {email && (
              <li>
                <a href={`mailto:${email}`}>{email}</a>
              </li>
            )}
            {phoneNumber && (
              <li>
                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
              </li>
            )}
            {link?.url && (
              <li className={styles.link}>
                <a href={link.url}>
                  <span>{link.text}</span>
                </a>
                <IconMS
                  className={styles.linkIcon}
                  name="open_in_new"
                  size="16px"
                />
              </li>
            )}
          </ul>
        )}

        {text && text.items.length > 0 && (
          <>
            {showLessText && showMoreText && truncateText ? (
              <Truncate
                truncateText={showLessText}
                expandText={showMoreText}
                className={styles.truncate}
              >
                <RichTextBlock text={text} />
              </Truncate>
            ) : (
              <RichTextBlock text={text} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ContactBlockItem;
