import styles from "./EventPage.module.scss";

import {
  AccordionItemBlockProps,
} from "components/blocks/AccordionItemBlock/AccordionItemBlock";
import EventPageHeroBlock, {
  EventPageHeroBlockProps,
} from "components/blocks/EventPageHeroBlock/EventPageHeroBlock";
import { HighlightedInfoBlockProps } from "components/blocks/HighlightedInfoBlock/HighlightedInfoBlock";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import ContactBlock, {
  ContactBlockProps,
} from "components/blocks/ContactBlock/ContactBlock";
import {
  BlockContainer,
  CardListBlock,
  GridContainer,
  GridItem,
  MultiColumn,
  TopPageBlocks,
} from "SiteComponents";
import { CardListBlockProps } from "components/blocks/CardListBlock/CardListBlock";

export interface EventPageProps {
  contentArea: ContentAreaProps;
  mainIntro?: string;
  highlightedInfo?: HighlightedInfoBlockProps;
  hero: EventPageHeroBlockProps;
  program?: AccordionItemBlockProps;
  contactBlock?: ContactBlockProps;
  relatedEvents?: CardListBlockProps;
  contributors?: ContactBlockProps;
}

const EventPage: React.FC<EventPageProps> = ({
  contentArea,
  hero,
  mainIntro,
  highlightedInfo,
  program,
  contactBlock,
  relatedEvents,
  contributors,
}) => {
  const blocksWidth = { xs: 12, lg: 6, lgOffset: 3 };
  return (
    <main className={styles.eventPage}>
      {hero && <EventPageHeroBlock {...hero} />}
      <MultiColumn>
        {(mainIntro || highlightedInfo) && (
          <MultiColumn.Left
            gridStyle={{ lgOffset: 2, lg: 6 }}
            nestedGridStyle={{ lg: 12, xl: 12, lgOffset: 0 }}
          >
            <TopPageBlocks
              mainIntro={mainIntro}
              highlightedInfo={highlightedInfo}
              noMargin={true}
              program={program}
            />
          </MultiColumn.Left>
        )}

        <MultiColumn.Right>
          <BlockContainer rowGap="md" horizontalSpacing="md">
            {contactBlock && <ContactBlock {...contactBlock} />}
          </BlockContainer>
        </MultiColumn.Right>

        <MultiColumn.Left>
          {contentArea && <ContentArea {...contentArea} />}
        </MultiColumn.Left>
        <MultiColumn.Left
          gridStyle={{ lgOffset: 2, lg: 6 }}
          nestedGridStyle={{ lg: 12, xl: 12, lgOffset: 0 }}
        >
          <GridContainer className={styles.bottomBlocks} noVertical>
            {contributors && (
              <GridItem {...blocksWidth}>
                <ContactBlock {...contributors} />
              </GridItem>
            )}
          </GridContainer>
        </MultiColumn.Left>
      </MultiColumn>

      {relatedEvents && (
        <BlockContainer>
          <CardListBlock {...relatedEvents} />
        </BlockContainer>
      )}
    </main>
  );
};

export default EventPage;
