import React, { FC, ReactNode, useState } from "react";
import styles from "./Modal.module.scss";

import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import FocusTrap from "../FocusTrap/FocusTrap";
import Spinner from "components/base/Spinner/Spinner";
import IconMS from "components/utils/IconMS/IconMS";
import useEscape from "src/utils/useEscape";
import classNames from "classnames";
import useDisableScroll from "src/utils/useDisableScroll";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  isVisible?: boolean;
  additionalTabableSelectors?: string[];
}

const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  isVisible = true,
  additionalTabableSelectors,
}) => {
  const [shouldClose, setShouldClose] = useState(false);
  useEscape(() => onClose());
  useDisableScroll(isOpen);

  const handleBackdropClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  const setFocus = (firstNode: HTMLElement) => {
    // Sets initial focus to the first focusable element in the modal
    // For unknown reasons, the focus is not always set correctly, so we set it with a timeout
    setTimeout(
      () => document.activeElement !== firstNode && firstNode?.focus(),
      700
    );
  };

  const handleClose = () => {
    setShouldClose(true);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  return (
    <>
      {!isVisible && <Spinner className={styles.spinner} />}
      <div
        className={classNames(styles.backdrop, {
          [styles.visible]: isVisible,
        })}
        onClick={handleBackdropClick}
      >
        <FocusTrap
          setFocus={setFocus}
          additionalTabableSelectors={additionalTabableSelectors}
        >
          <BlockContainer
            className={classNames(styles.modal, {
              [styles.modalOpen]: isOpen,
              [styles.modalShouldClose]: shouldClose,
            })}
          >
            <div className={styles.buttonContainer}>
              <button className={styles.closeButton} onClick={handleClose}>
                <IconMS name="close" />
              </button>
            </div>
            <div className={styles.childrenContainer}>{children}</div>
          </BlockContainer>
        </FocusTrap>
      </div>
    </>
  );
};

export default Modal;
