import { FC, CSSProperties, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./IconMS.module.scss";
import FontFaceObserver from "fontfaceobserver";

type IconSize =
  | string
  | {
      xs?: string;
      sm?: string;
      md?: string;
      lg?: string;
      xl?: string;
    };
interface IconMSProps {
  className?: string;
  name: string;
  size?: IconSize;
  weight?: "100" | "200" | "300" | "400" | "500" | "600" | "700";
  grade?: number;
  opticalSize?: number;
  fill?: boolean;
}

const IconMS: FC<IconMSProps> = ({
  className,
  name,
  size,
  weight = "400",
  grade = 0,
  opticalSize = 20,
  fill = false,
}) => {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const iconSize = calcIconSize(size);
  const style = {
    "--icon-weight": weight,
    "--icon-grade": Math.max(-25, Math.min(grade, 200)),
    "--icon-optical-size": Math.max(20, Math.min(opticalSize, 48)),
    "--icon-fill": fill ? 1 : 0,
    ...iconSize,
  } as CSSProperties;

  useEffect(() => {
    const font = new FontFaceObserver("Material Symbols Sharp");
    font.load().then(() => {
      setFontsLoaded(true);
    });
  }, []);

  return (
    <span
      aria-hidden={true}
      className={classNames(
        "material-symbols-sharp",
        styles.iconMS,
        className,
        {
          [styles.fontsLoaded]: fontsLoaded,
        }
      )}
      style={style}
    >
      {name}
    </span>
  );
};

export default IconMS;

const pxToRem = (pxString: string) => {
  const baseSize = 16;
  const pxValue = parseFloat(pxString);
  const remValue = pxValue / baseSize;

  return `${remValue}rem`;
};

function calcIconSize(size: IconSize | undefined): object {
  const defaultValue = pxToRem("24px");
  if (typeof size === "object") {
    return {
      "--icon-size-xs": size.xs && pxToRem(size.xs),
      "--icon-size-sm":
        (size.sm && pxToRem(size.sm)) || (size.xs && pxToRem(size.xs)),
      "--icon-size-md":
        (size.md && pxToRem(size.md)) ||
        (size.sm && pxToRem(size.sm)) ||
        (size.xs && pxToRem(size.xs)),
      "--icon-size-lg":
        (size.lg && pxToRem(size.lg)) ||
        (size.md && pxToRem(size.md)) ||
        (size.sm && pxToRem(size.sm)) ||
        (size.xs && pxToRem(size.xs)),
      "--icon-size-xl":
        (size.xl && pxToRem(size.xl)) ||
        (size.lg && pxToRem(size.lg)) ||
        (size.md && pxToRem(size.md)) ||
        (size.sm && pxToRem(size.sm)) ||
        (size.xs && pxToRem(size.xs)),
    };
  }

  return {
    "--icon-size-xs": (size && pxToRem(size)) || defaultValue,
    "--icon-size-sm": (size && pxToRem(size)) || defaultValue,
    "--icon-size-md": (size && pxToRem(size)) || defaultValue,
    "--icon-size-lg": (size && pxToRem(size)) || defaultValue,
    "--icon-size-xl": (size && pxToRem(size)) || defaultValue,
  };
}
