import React from "react";
import classNames from "classnames";
import styles from "./AlertBlock.module.scss";
import { Icon, RichTextBlock } from "SiteComponents";
import Truncate from "components/base/Truncate/Truncate";
import { ContentAreaProps } from "components/utils/ContentArea/ContentArea";

export interface AlertBlockProps {
  title?: string;
  message?: ContentAreaProps;
  alertType: "informative" | "positive" | "negative" | "warning";
  showMoreText: string;
  showLessText: string;
}

const AlertBlock: React.FC<AlertBlockProps> = ({
  title,
  message,
  alertType,
  showMoreText,
  showLessText,
}) => {
  return (
    <div className={classNames(styles.alertBlock, styles[alertType])}>
      <Icon className={styles.icon} name={`alert-block-${alertType}`} />
      <div className={styles.message}>
        {title && <h2>{title}</h2>}
        <Truncate truncateText={showLessText} expandText={showMoreText} className={styles.truncate}>
          {message && <RichTextBlock
            text={message}
          />}
        </Truncate>
      </div>
    </div>
  );
};

export default AlertBlock;
